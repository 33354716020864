import { RoleType } from './Role';

export interface RoleTypeUIContextType {
    currentRoleModel: RoleType[];
    newRole: RoleType[];
    removeRole: RoleType[];
    updateCallback?: () => void;
    updateUI: () => void;
    setUpdateCallback: (callback: () => void) => void;
    currentRoleID: string
}

export const initialRole: RoleType[] = [{
    roleID: -1,
    roleName: 'Role not selected',
    roleDescription: null,
    reachRequired: null,
    roleOwner: null,
    roleOwnerEmail: null,
    defaultReachLevel: null,
}];

export const createInitialContext = (): RoleTypeUIContextType => {
    let updateCallback: () => void = () => {};
    return {
        currentRoleModel: initialRole,
        newRole: [],
        removeRole: [],
        updateUI: () => {
            if (updateCallback) {
              updateCallback();
            }
          },
          setUpdateCallback: (callback: () => void) => {
            updateCallback = callback;
          },
          currentRoleID: ""
    };
};

