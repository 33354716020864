import { EmployeeType } from '../models/Employee';
import APIService from '../api/APIService';

class EmployeeService {
  async getEmployees(): Promise<EmployeeType[]> {
    try {
      // Fetch employees from the API
      const employees: EmployeeType[] = await APIService.getEmployees();
      return employees.map(employee => ({
        ...employee,
        fullNameWithID: `${employee.fullName} (${employee.employeeID})`
      }));
    } catch (error) {
      console.error('Error fetching employees:', error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new EmployeeService();

