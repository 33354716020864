import { RoleAssignmentType } from '../models/RoleAssignment';
import { OrgHierarchyTabularType } from '../models/OrgHierarchyTabular';
import { OrgHierarchyNodeType, OrgHierarchyNode } from '../models/OrgHierarchyNode';
import { CompleteRoleAssignmentType } from '../models/CompleteRoleAssignment';
import {RoleAssignmentUIContextType} from '../models/RoleAssignmentUIContext'

export class RoleAssignmentModelUtilities {

    // Apply role assignments to the complete role assignments structure
    public static applyRoleAssignments(roleAssignments: RoleAssignmentType[], orgHierarchyTree: OrgHierarchyNodeType): void {
        if (!orgHierarchyTree) {
            console.warn('Org hierarchy tree is not initialized. Cannot apply role assignments.');
            return;
        }
        // Implementation of applying role assignments to the hierarchy
    }

    // Initialize orgHierarchyTree asynchronously
    public static async initializeOrgHierarchy(): Promise<OrgHierarchyNodeType | null> {
        try {
            const orgHierarchyData = await this.fetchOrgHierarchy(); // Example async function call
            return this.buildOrgHierarchy(orgHierarchyData);
        } catch (error) {
            console.error('Failed to initialize org hierarchy:', error);
            throw error; // Handle error as needed
        }
    }

    // build org hierarchy from tabular data
    public static buildOrgHierarchy(tabularData: OrgHierarchyTabularType[]): OrgHierarchyNodeType | null {
        const nodeMap: Map<number, OrgHierarchyNode> = new Map();

        // Create nodes for each entry
        tabularData.forEach(row => {
            nodeMap.set(row.nodeID ?? 0, new OrgHierarchyNode(row.nodeID, row.nodeName));
        });

        // Set up parent-child relationships
        tabularData.forEach(row => {
            const node = nodeMap.get(row.nodeID ?? 0);
            if (row.parentNodeID !== null && node) {
                const parentNode = nodeMap.get(row.parentNodeID);
                parentNode?.addChild(node);
            }
        });

        // Find and return the root node (node without a parent)
        const rootRow = tabularData.find(row => row.parentNodeID === null);
        return rootRow ? nodeMap.get(rootRow.nodeID ?? 0) || null : null;
    }

    // fetch org hierarchy data asynchronously
    private static async fetchOrgHierarchy(): Promise<OrgHierarchyTabularType[]> {
        // Implement your API call or data fetching logic here
        throw new Error('Not implemented exception');
    }

    // Function to create a CompleteRoleAssignmentType from an OrgHierarchyTabularRow
    private static getCompleteRoleAssignmentFromOrgHierarchyTabularRow(
        row: OrgHierarchyTabularType,
        roleAssignments: RoleAssignmentType[],
        context: RoleAssignmentUIContextType
    ): CompleteRoleAssignmentType {
        const matchingRoleAssignment = roleAssignments.find(ra => ra.nodeID === row.nodeID);
        
        const roleAssignmentID = matchingRoleAssignment ? matchingRoleAssignment.roleAssignmentID : null;
        const assignmentType = matchingRoleAssignment ? matchingRoleAssignment.assignmentType : 2;
        const roleID = context.currentRoleID
        const employeeID = context.currentEmployeeID;
    
        const converted: CompleteRoleAssignmentType = {
            id: `${row.nodeID}-${roleID}-${employeeID}-${row.fccID}-${row.stationType}`,
            assignmentType: assignmentType,
            roleID: roleID,
            employeeID: employeeID,
            roleAssignmentID: roleAssignmentID,
            nodeID: row.nodeID,
            nodeName: row.nodeName,
            parentNodeID: row.parentNodeID,
            level: row.level,
            fullyQualifiedNodeDescription: row.fullyQualifiedNodeDescription,
            reverseFullyQualifiedNodeDescription: row.reverseFullyQualifiedNodeDescription,
            totalCompanyLongName: row.totalCompanyLongName,
            divisionLongName: row.divisionLongName,
            level2LongName: row.level2LongName,
            subsidiaryorLOBLongName: row.subsidiaryorLOBLongName,
            consolidatedRegionsLongName: row.consolidatedRegionsLongName,
            regionLongName: row.regionLongName,
            MktOrBranchOrCorpDeptLongName: row.MktOrBranchOrCorpDeptLongName,
            BUOrCostCenterOrStationLongName: row.BUOrCostCenterOrStationLongName,
            assignmentStatus: 'unassigned',
            isMarket: row.isMarket,
            marketID: row.marketID,
            marketName: row.marketName,
            businessUnitID: row.businessUnitID,
            businessUnitName: row.businessUnitName,
            isSharedServiceBusinessUnit: row.isSharedServiceBusinessUnit,
            isStation: row.isStation,
            stationType: row.stationType,
            isAssigned: 0,
            isPermitted: 0,
            descendantAssignedCount: 0,
            descendantPermittedCount: 0
        };
        return converted;
    }
    

    public static createCompleteAssignmentFromRoleAssignmentsAndOrgHierarchy(
        orgHierarchyTabular: OrgHierarchyTabularType[],
        roleAssignments: RoleAssignmentType[],
        orgHierarchyTree: OrgHierarchyNode,
        context: RoleAssignmentUIContextType
    ): { completeAssignments: CompleteRoleAssignmentType[], removedAssignments: RoleAssignmentType[] } {
        const completeRoleAssignments: CompleteRoleAssignmentType[] = [];
        const removedAssignments: RoleAssignmentType[] = [];

        // Create initial completeRoleAssignments array based on orgHierarchyTabular
        orgHierarchyTabular.forEach(row => {
            const completeAssignment = this.getCompleteRoleAssignmentFromOrgHierarchyTabularRow(row, roleAssignments, context);
            completeRoleAssignments.push(completeAssignment);
        });

        // Apply role assignments to completeRoleAssignments
        roleAssignments.forEach(roleAssignment => {
            const assignedNode = completeRoleAssignments.find(entry => entry.nodeID === roleAssignment.nodeID);
            if (assignedNode) {
                assignedNode.isAssigned = 1;
                assignedNode.assignmentStatus = 'assigned';
                this.cascadeAssignmentsToPermitted(completeRoleAssignments, assignedNode, orgHierarchyTree, removedAssignments);
            }
        });

        // Set descendant counts after the cascade completes
        this.rollupDescendantCounts(completeRoleAssignments, orgHierarchyTree);

        // Return the modified completeRoleAssignments and removed assignments
        return { completeAssignments: completeRoleAssignments, removedAssignments };
    }

    private static cascadeAssignmentsToPermitted(
        completeRoleAssignments: CompleteRoleAssignmentType[],
        assignedNode: CompleteRoleAssignmentType,
        orgHierarchyTree: OrgHierarchyNode,
        removedAssignments: RoleAssignmentType[]
    ): void {
        // Recursive function to update descendants
        const updateDescendants = (node: OrgHierarchyNode): void => {
            node.children.forEach(child => {
                const descendant = completeRoleAssignments.find(entry => entry.nodeID === child.nodeID);
                if (descendant) {
                    if (descendant.assignmentStatus === 'assigned') {
                        removedAssignments.push({
                            nodeID: descendant.nodeID,
                            employeeID: descendant.employeeID,
                            roleID: descendant.roleID,
                            roleAssignmentID: descendant.roleAssignmentID,
                            assignmentType: 2
                        });
                    }
                    descendant.isPermitted = 1;
                    descendant.assignmentStatus = 'permitted';
                    updateDescendants(child); // Recursively update descendants
                }
            });
        };

        // Start updating from the assigned node's corresponding node in orgHierarchyTree
        const assignedOrgNode = orgHierarchyTree.findNodeById(assignedNode.nodeID);
        if (assignedOrgNode) {
            updateDescendants(assignedOrgNode);
        }
    }

    private static rollupDescendantCounts(
        completeRoleAssignments: CompleteRoleAssignmentType[],
        orgHierarchyTree: OrgHierarchyNode
    ): void {
        /**
         * Helper function to recursively sum descendant counts.
         * @param node - The current node being processed in the hierarchy tree.
         * @returns An object containing the counts of assigned and permitted descendants.
         */
        const sumDescendantCounts = (node: OrgHierarchyNode): { assigned: number, permitted: number } => {
            let assignedCount = 0;
            let permittedCount = 0;

            // Iterate through each child of the current node
            node.children.forEach(child => {
                // Find the corresponding role assignment for the child node
                const childNode = completeRoleAssignments.find(entry => entry.nodeID === child.nodeID);
                if (childNode) {
                    // Recursively calculate counts for the child's descendants
                    const childCounts = sumDescendantCounts(child);
                    assignedCount += childNode.isAssigned + childCounts.assigned;
                    permittedCount += childNode.isPermitted + childCounts.permitted;
                }
            });

            // Find the corresponding role assignment for the current node
            const currentNode = completeRoleAssignments.find(entry => entry.nodeID === node.nodeID);
            if (currentNode) {
                // Update the current node's descendant counts
                currentNode.descendantAssignedCount = assignedCount;
                currentNode.descendantPermittedCount = permittedCount;
            }

            // Return the cumulative counts for the current node
            return { assigned: assignedCount, permitted: permittedCount };
        };

        // Start the summing process from the root node of the hierarchy tree
        const rootNode = orgHierarchyTree.findNodeById(orgHierarchyTree.nodeID);
        if (rootNode) {
            sumDescendantCounts(rootNode);
        }
    }

}
