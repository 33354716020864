import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Tooltip, TextField, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGridPremium, useGridApiRef, GridColDef, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-premium';
import { RoleType, RoleCreateType } from '../models/Role'
import RoleService from '../services/RoleService'
import { useRole } from '../RoleProvider';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { GridRowModesModel, GridEventListener } from '@mui/x-data-grid-premium';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';

//Add Reole
function AddRecordForm({ setShowForm }: { setShowForm: React.Dispatch<React.SetStateAction<boolean>>; }) {
    // function AddRecordForm({ setShowForm, setRows }: { setShowForm: React.Dispatch<React.SetStateAction<boolean>>; setRows: React.Dispatch<React.SetStateAction<RoleType[]>>; }) {
    const [formInputs, setFormInputs] = useState<RoleCreateType>({
        roleName: "",
        roleDescription: "",
        reachRequired: 0,
        roleOwner: "",
        roleOwnerEmail: "",
        defaultReachLevel: 0,
    });
    const { updateUI } = useRole();

    const handleChange = (e: any) => {
        const val = e.target.value
        if (e.target.name === 'roleName' && val.length > 10) {
            enqueueSnackbar('Role Name can only be 10 characters long.', { variant: 'error' });
        }
        setFormInputs((prevState) => ({
            ...prevState,
            [e.target.name] : e.target.value
        }))

    };

    const handleSubmit = async(event: any) => {
        event.preventDefault();
        console.log(formInputs);
        try {
            if (formInputs != null) {
                RoleService.createRole(formInputs);
                enqueueSnackbar('Role creation success', { variant: 'success' });
                setTimeout(() => {}, 2000);
                // setRowModesModel({ ...rowModesModel, [roleId]: { mode: GridRowModes.View } });
                // const msg = 'Role creation success, role id: '+result;
                // await RoleService.loadRoles();
               
                // setRows(roleList) 
                updateUI();
                window.location.reload();
            }
        } catch (error) {
            console.error("Error occured while deleting role");
        } finally {
            setFormInputs({roleName: "",
                roleDescription: "",
                reachRequired: 0,
                roleOwner: "",
                roleOwnerEmail: "",
                defaultReachLevel: 0,});
        }
        setShowForm(false);
    };
    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', gap: 2, flexDirection: 'row', flexWrap: 'wrap', margin: 4, padding: 2, border: '1px solid', borderColor: 'grey.200', borderRadius: 1, }}>
        <div>    
            <TextField label="Role Name" name="roleName" value={formInputs.roleName} onChange={handleChange} type="text" required />
            <TextField label="Role Description" name="roleDescription" value={formInputs.roleDescription} onChange={handleChange} type="text" />
            <TextField label="Reach Required" name="reachRequired" type="number" value={formInputs.reachRequired} onChange={handleChange} />
            <TextField label="Role Owner" name="roleOwner" value={formInputs.roleOwner} type="text" onChange={handleChange} />
            <TextField label="Role Owner Email" name="roleOwnerEmail" value={formInputs.roleOwnerEmail} type="email" onChange={handleChange} />
            <TextField label="Default Reach Level" name="defaultReachLevel" type="number" value={formInputs.defaultReachLevel} onChange={handleChange} />

            <Button type="submit" variant="contained" color="primary" size="large">Submit</Button>
        </div>
        </Box>
    );
}

function RoleManager() {
    const { currentRoleModel, updateUI } = useRole();
    const [roles, setRoles] = useState<RoleType[]>([]);
    const [rows, setRows] = useState<RoleType[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ccRoleID, setCcRoleID] = useState('');
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(true);
    const [paginationModel, setPaginationModel] = useState({pageSize:15, page:0})
    const pageSizeOptions = [5, 15, 20, 30, 50]
    const [open, setOpen] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [rowToDelete, setRowToDelete] = useState<RoleType>();
    const [showAddRoleForm, setShowAddRoleForm] = useState(false);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>(
        {}
    );

    const handlePaginationModelChange = (model: any) => {
        setPaginationModel(model);
    }

    //Handler for selected Role ID
    const handleRoleSelect = (event: any, value: RoleType | null) => {
        if (value) {
            setCcRoleID(value.roleID.toString());
            RoleService.updateContextWithRoleID(value.roleID.toString());
        }
    };


    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    //Fetches and sets all 'Role' list when UI is up.
    useEffect(() => {
        async function fetchRoles() {
            try {
                setLoading(true);
                const roleList = await RoleService.getRoles();
                setRoles(roleList);
                setRows(roleList);
            } catch (error) {
                console.error('Failed to fetch roles', error);
            } finally {
                setLoading(false)
            }
        }
        fetchRoles();
    }, [currentRoleModel]);

    //Handler which listens for row editing to complete
    const handleRowEditStop: GridEventListener<"rowEditStop"> = (
        params,
        event
    ) => {
        event.defaultMuiPrevented = true;
    };

    //Handler for Refresh button click 
    const loadRoles = async () => {
        try {
            setLoading(true);
            await RoleService.loadRoles();
        } catch (error) {
            console.error('Error loading roless:', error);
        } finally {
            setLoading(false);
        }
    };


    //Handler for creating new role
    const handleCreateNewRole = async () => {
        setShowAddRoleForm(true);
        // setRows((prevRows) => )
    }

    //Handler for edit icon click
    const handleEditClick = async (event: any, value: RoleType) => {
        // console.log("Edited value", value.roleID)
        setRowModesModel({ ...rowModesModel, [value.roleID]: { mode: GridRowModes.Edit } });
    }

    //delete icon click
    const handleDeleteClick = (value: RoleType) => {
        setOpen(true)
        setRowToDelete(value);
    };
    //save icon click
    const handleSaveClick = (value: RoleType) => () => {
        apiRef.current.getRow(value.roleID);
        setShouldUpdate(true);
        setRowModesModel({ ...rowModesModel, [value.roleID]: { mode: GridRowModes.View } });
    };

    //cancel-updates icon click
    const handleCancelClick = (value: RoleType) => () => {
        setRowModesModel({
            ...rowModesModel,
            [value.roleID]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    //handler for calling delete role api
    const processDelete = async () => {
        // console.log("Inside process delete")
        try {
            if (rowToDelete != null) {
                RoleService.deleteRole(rowToDelete);
                setRows(rows.filter((row) => row.roleID !== rowToDelete.roleID));
                enqueueSnackbar('Role deleted.', { variant: 'success' });
            }
        } catch (error) {
            console.error("Error occured while deleting role");
        } finally {
            setRowToDelete(undefined);
            setOpen(false);
        }
    }

    //handle confirm delete option
    const handleConfirmDelete = (e: any) => {
        setOpen(true);
        processDelete();
    }
    //handle cancel delete option
    const handleCancelDelete = () => {
        setOpen(false)
    }

    //handler for calling update role api
    const processRow = (row: RoleType) => {
        console.log("Processing row")
        try {
            if (shouldUpdate === true) {
                RoleService.saveRole(row);
                enqueueSnackbar('Role changes saved.', { variant: 'success' });
                updateUI();
            }
        } catch (error) {
            console.error("Error occured while processing row update");
        } finally {
            if (shouldUpdate === true) {
                setShouldUpdate(false);
            }
        }
    }

    //handler for processing row updates
    const processRowUpdate = (newRow: RoleType) => {
        const updatedRow = { ...newRow };
        processRow(updatedRow);
        setRows(rows.map((row) => (row.roleID === newRow.roleID ? updatedRow : row)));
        console.log("Row updated", updatedRow);
        return updatedRow;
    };

    //actions icon
    const editRoleButton: GridColDef = {
        field: 'editRole',
        headerName: 'Actions',
        type: 'actions',
        width: 120,
        getActions: (params) => {
            const roleID = params.row.roleID
            const isInEditMode = rowModesModel[roleID]?.mode === GridRowModes.Edit;

            if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        onClick={handleSaveClick(params.row)}
                    />,
                    <GridActionsCellItem
                        icon={<CancelIcon />}
                        label="Cancel"
                        className="textPrimary"
                        onClick={handleCancelClick(params.row)}
                        color="inherit"
                    />
                ];
            }

            return [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={(e) => handleEditClick(e, params.row)}
                    color="inherit"
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={(e) => handleDeleteClick(params.row)}
                    color="inherit"
                />
            ];
        }
    };

    //grid columns
    const columns: GridColDef[] = [
        editRoleButton,
        { field: 'roleID', headerName: 'Role ID', width: 120 },
        { field: 'roleName', headerName: 'Role Name', width: 120, editable: true },
        { field: 'roleDescription', headerName: 'Role Description', width: 420, editable: true },
        { field: 'reachRequired', headerName: 'Reach Required', width: 120, editable: true },
        { field: 'roleOwner', headerName: 'Role Owner', width: 120, editable: true },
        { field: 'roleOwnerEmail', headerName: 'Role Owner Email', width: 300, editable: true },
        { field: 'defaultReachLevel', headerName: 'Default Reach Level', width: 150, editable: true },
        { field: 'roleDescriptionWithID', headerName: 'Role Description with ID', width: 420, editable: true },
    ];

    return (
        <Box sx={{ height: '70vh', width: '100%' }}>
            <Typography variant="h3" component="h3">
                Manage Roles
            </Typography>
            <Box sx={{ display: 'flex', marginBottom: 2 }}>
                <Autocomplete
                    options={roles}
                    getOptionLabel={(option) => option.roleDescriptionWithID || ""}
                    onChange={handleRoleSelect}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Role"
                            variant="outlined"
                            sx={{ marginRight: 2, width: 300 }}
                        />
                    )}
                />
                <Tooltip title="Refresh Role">
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ marginRight: 2, height: 56 }}
                        onClick={loadRoles}
                        startIcon={<RefreshIcon />}
                    />
                </Tooltip>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title="Add new Role">
                        <Button color="primary" startIcon={<AddIcon />} onClick={handleCreateNewRole}>
                            Add Role
                        </Button>
                    </Tooltip>
                </div>
            </Box>

            {showAddRoleForm && <AddRecordForm setShowForm={setShowAddRoleForm} />}
            <DataGridPremium
                rows={rows}
                apiRef={apiRef}
                columns={columns}
                loading={loading}
                editMode='row'
                getRowId={row => row.roleID}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                rowSpacingType="border"
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={pageSizeOptions}
            />
            <Dialog //dialog-box when delete-cell icon is clicked
                open={open}
                onClose={handleCancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Role Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this role? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={(e) => handleConfirmDelete(e)} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
};

export default RoleManager;