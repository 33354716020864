import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import  RoleService from './services/RoleService';

type RoleContextType = typeof RoleService.context;

const RoleContext = createContext<RoleContextType>({
  ...RoleService.context,
});

export const RoleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [context, setContext] = useState(RoleService.context);

  useEffect(() => {
    const handleUpdate = () => {
      setContext({ ...RoleService.context });
    };

    RoleService.context.setUpdateCallback(handleUpdate);

    return () => {
      RoleService.context.setUpdateCallback(() => {});
    };
  }, []);

  return (
    <RoleContext.Provider value={{ ...context }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => {
  return useContext(RoleContext);
};
