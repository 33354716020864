import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import  RoleAssignmentService from './services/RoleAssignmentService';

// Define the RowExpansionState type
type RowExpansionState = string;

type RoleAssignmentContextType = {
  expansionState: RowExpansionState[];
  setExpansionState: (state: RowExpansionState[]) => void;
} & typeof RoleAssignmentService.context;

const RoleAssignmentContext = createContext<RoleAssignmentContextType>({
  ...RoleAssignmentService.context,
  expansionState: [] as RowExpansionState[],
  setExpansionState: (state: RowExpansionState[]) => {},
});

export const RoleAssignmentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [context, setContext] = useState(RoleAssignmentService.context);
  const [expansionState, setExpansionState] = useState<RowExpansionState[]>([]);

  useEffect(() => {
    const handleUpdate = () => {
      setContext({ ...RoleAssignmentService.context });
    };

    RoleAssignmentService.initAsyncData();
    RoleAssignmentService.context.setUpdateCallback(handleUpdate);

    return () => {
      RoleAssignmentService.context.setUpdateCallback(() => {});
    };
  }, []);

  return (
    <RoleAssignmentContext.Provider value={{ ...context, expansionState, setExpansionState }}>
      {children}
    </RoleAssignmentContext.Provider>
  );
};

export const useRoleAssignment = () => {
  return useContext(RoleAssignmentContext);
};

export type {
  RowExpansionState
};
