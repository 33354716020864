import { LicenseInfo } from '@mui/x-license';
import {AppBar, Drawer, Toolbar, Typography, List, ListItem} from "@mui/material"
import { BrowserRouter, Link, Routes, Route } from "react-router-dom"
import RoleAssignmentManager from "./RoleAssignmentManager"
import RoleManager from "./RoleManager"


LicenseInfo.setLicenseKey('28c13635d3d96713e4fcdab33f4534a7Tz05ODQyMixFPTE3NTgzOTA1MjQwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxQVj1pbml0aWFsLEtWPTI=');

const drawerWidth = 200
const simpleStyles = {
    drawer: {
        width: drawerWidth,
        "& .MuiBackdrop-root":{display: "none"}
    },
    // define style object for the paper that the drawer is defined within
    drawerPaper:
    {
            width: drawerWidth,
            backgroundColor: "rgba(120,120,120,0.2)",
    },
    content:{
        marginLeft: drawerWidth,
        padding: 3
    }
}

export default function NavDrawer(){
    return(
        <BrowserRouter>
            <div>
                <AppBar position="fixed" sx={{zIndex:9999}}>
                    <Toolbar>
                        <Typography variant='h6'>
                            Security Roles Administration
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer 
                    disableEnforceFocus
                    variant='temporary' 
                    open={true} 
                    sx={simpleStyles.drawer}
                    PaperProps=
                    {{
                        sx: simpleStyles.drawer,
                        elevation:9
                    }}
                    >
                    <Toolbar/>
                    <List>
                        {
                        [
                            {text:"Role Assignment", route: "/roleAssignmentGrid"},           
                        ]
                        .map((nav, index) =>
                            (
                            <ListItem key = {nav.text}>
                                <Link to={nav.route}>{nav.text}</Link></ListItem>
                            ))
                        },
                                              {
                        [
                            {text:"Roles", route: "/rolesGrid"},
                        ]
                        .map((nav, index) =>
                            (
                            <ListItem key = {nav.text}>
                                <Link to={nav.route}>{nav.text}</Link></ListItem>
                            ))
                        }
                    </List>
                </Drawer>
                <main style={simpleStyles.content}>
                    <Toolbar/>
                    <Routes>
                        <Route path={"/"} element={<RoleAssignmentManager/>}></Route>
                        <Route path={"/roleAssignmentGrid"} element={<RoleAssignmentManager/>}></Route>
                        <Route path={"/rolesGrid"} element={<RoleManager/>}></Route>
                    </Routes>
                </main>
            </div>
        </BrowserRouter>
    )
}