import { OrgHierarchyTabularType } from './OrgHierarchyTabular';

export interface OrgHierarchyNodeType {
  nodeID: number;
  nodeName: string | null;
  children?: OrgHierarchyNodeType[];
}

export class OrgHierarchyNode implements OrgHierarchyNodeType {
  nodeID: number;
  nodeName: string | null;
  children: OrgHierarchyNode[];

  // Bogus static node
  static readonly bogusNode: OrgHierarchyNode = new OrgHierarchyNode(-1, 'Bogus Node');

  constructor(nodeID: number, nodeName: string | null) {
    this.nodeID = nodeID;
    this.nodeName = nodeName;
    this.children = [];
  }

  addChild(node: OrgHierarchyNode): void {
    this.children.push(node);
  }

  static fromTabularData(tabularData: OrgHierarchyTabularType[]): OrgHierarchyNode {
    const nodeMap: Map<number, OrgHierarchyNode> = new Map();

    // Create nodes for each entry
    tabularData.forEach(row => {
      nodeMap.set(row.nodeID ?? 0, new OrgHierarchyNode(row.nodeID, row.nodeName));
    });

    // Set up parent-child relationships
    tabularData.forEach(row => {
      const node = nodeMap.get(row.nodeID ?? 0);
      if (row.parentNodeID !== null && node) {
        const parentNode = nodeMap.get(row.parentNodeID);
        parentNode?.addChild(node);
      }
    });

    // Find and return the root node (node without a parent)
    const rootRow = tabularData.find(row => row.parentNodeID === null);
    return rootRow ? nodeMap.get(rootRow.nodeID ?? 0) || OrgHierarchyNode.bogusNode : OrgHierarchyNode.bogusNode;
  }

  getDescendantsOfNode(nodeID: number): number[] {
    const result: number[] = [];

    // Helper function to recursively collect descendants
    const collectDescendants = (node: OrgHierarchyNode | null) => {
      if (!node) return;
      result.push(node.nodeID); // Add current node ID to result

      node.children.forEach(child => collectDescendants(child));
    };

    // Find the node with the given nodeID
    const startNode = this.findNodeById(nodeID);

    // If node found, collect descendants
    if (startNode) {
      collectDescendants(startNode);
    }

    return result;
  }

  public findNodeById(nodeID: number): OrgHierarchyNode | null {
    // Helper function to find a node by nodeID recursively
    const findNode = (node: OrgHierarchyNode | null): OrgHierarchyNode | null => {
      if (!node) return null;
      if (node.nodeID === nodeID) return node;

      // Recursively search in children
      for (const child of node.children) {
        const found = findNode(child);
        if (found) return found;
      }

      return null;
    };

    // Start searching from the root
    return findNode(this);
  }
}
