export interface OrgHierarchyTabularType {
    nodeID: number;
    nodeName: string | null;
    parentNodeID: number | null;
    level: number;
    fullyQualifiedNodeDescription: string | null;
    reverseFullyQualifiedNodeDescription: string | null;
    totalCompanyLongName: string | null;
    divisionLongName: string | null;
    level2LongName: string | null;
    subsidiaryorLOBLongName: string | null;
    consolidatedRegionsLongName: string | null;
    regionLongName: string | null;
    MktOrBranchOrCorpDeptLongName: string | null;
    BUOrCostCenterOrStationLongName: string | null;
    isMarket: number | null;
    marketID: string | null;
    marketName: string | null;
    businessUnitID: string | null;
    businessUnitName: string | null;
    isSharedServiceBusinessUnit: number | null;
    isStation: number | null;
    stationType: string | null;
    fccID: string | null;
}

export class OrgHierarchyTabular {
    static mergeDuplicateNodes(orgHierarchyWithDupes: OrgHierarchyTabularType[]): OrgHierarchyTabularType[] {
        const nodeMap = new Map<number, OrgHierarchyTabularType>();

        orgHierarchyWithDupes.forEach(node => {
            const existingNode = nodeMap.get(node.nodeID);
            if (existingNode) {
                // Combine stationType and fccID into a CSV format
                const stationTypes = new Set<string>();
                const fccIDs = new Set<string>();

                if (existingNode.stationType) {
                    existingNode.stationType.split(',').forEach(station => stationTypes.add(station));
                }
                if (node.stationType) {
                    node.stationType.split(',').forEach(station => stationTypes.add(station));
                }

                if (existingNode.fccID) {
                    existingNode.fccID.split(',').forEach(fcc => fccIDs.add(fcc));
                }
                if (node.fccID) {
                    node.fccID.split(',').forEach(fcc => fccIDs.add(fcc));
                }

                existingNode.stationType = Array.from(stationTypes).join(',');
                existingNode.fccID = Array.from(fccIDs).join(',');
            } else {
                nodeMap.set(node.nodeID, { ...node });
            }
        });

        return Array.from(nodeMap.values());
    }
}
