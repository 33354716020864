import { CompleteRoleAssignmentType } from '../models/CompleteRoleAssignment';
import { RoleAssignmentType } from '../models/RoleAssignment';

export interface RoleAssignmentUIContextType {
  currentCompleteRoleAssignmentModel: CompleteRoleAssignmentType[];
  currentRoleAssignments: RoleAssignmentType[];
  currentUIQueryConditions: { employeeID: string; roleID: string };
  newRoleAssignments: RoleAssignmentType[];
  removeRoleAssignments: RoleAssignmentType[];
  updateCallback?: () => void;
  updateUI: () => void;
  setUpdateCallback: (callback: () => void) => void;
  currentEmployeeID: string;
  currentRoleID: string
}

export const initialCompleteRoleAssignment: CompleteRoleAssignmentType[] = [
  {
    id: '-1',
    assignmentType: -1,
    roleID: "-1",
    employeeID: "-1",
    roleAssignmentID: null,
    nodeID: -1,
    nodeName: 'Role and Employee not selected',
    parentNodeID: null,
    level: -1,
    fullyQualifiedNodeDescription: 'Please select either an employeeID to load all the employees roles or an employee and a roleID and press the load button',
    reverseFullyQualifiedNodeDescription: null,
    totalCompanyLongName: null,
    divisionLongName: null,
    level2LongName: null,
    subsidiaryorLOBLongName: null,
    consolidatedRegionsLongName: null,
    regionLongName: null,
    MktOrBranchOrCorpDeptLongName: null,
    BUOrCostCenterOrStationLongName: null,
    assignmentStatus: null,
    isMarket: null,
    marketID: null,
    marketName: null,
    businessUnitID: null,
    businessUnitName: null,
    isSharedServiceBusinessUnit: null,
    isStation: null,
    stationType: null,
    isAssigned: 0,
    isPermitted: 0,
    descendantAssignedCount: 0,
    descendantPermittedCount: 0,
  }
];

export const createInitialContext = (): RoleAssignmentUIContextType => {
  let updateCallback: () => void = () => {};

  return {
    currentCompleteRoleAssignmentModel: initialCompleteRoleAssignment,
    currentRoleAssignments: [],
    currentUIQueryConditions: { employeeID: "notset", roleID: "notset" },
    newRoleAssignments: [],
    removeRoleAssignments: [],
    updateUI: () => {
      if (updateCallback) {
        updateCallback();
      }
    },
    setUpdateCallback: (callback: () => void) => {
      updateCallback = callback;
    },
    currentEmployeeID: "",
    currentRoleID: ""
  };
};