import { RoleType, RoleCreateType, RoleIDType } from '../models/Role';
import APIService from '../api/APIService';
import { RoleTypeUIContextType, createInitialContext } from '../models/RoleUIContext'
;
class RoleService {
  public context: RoleTypeUIContextType = createInitialContext();
  private roles: RoleType[] = [];
  //fetch all roles
  async getRoles(): Promise<RoleType[]> {
    try {
      // Fetch roles from the API
      const roles: RoleType[] = await APIService.getRoles();
      this.roles = roles;
      return roles.map(role => ({
        ...role,
        roleDescriptionWithID: `${role.roleDescription} (${role.roleID})`
      }));
    } catch (error) {
      console.error('Error fetching roles:', error);
      throw error;
    }
  }

  public updateContextWithRoleID(roleID: string) {
    this.context.currentRoleID = roleID;
    this.context.updateUI();
}

  /* --------------------------------------------RoleGrid ------------------------*/

  /**
   * loadRoles: called by UI
   */
  public async loadRoles(): Promise<void> {
    try {
      let gridRoles: RoleType[] = this.roles;
      if (gridRoles.length === 0)
        gridRoles = await APIService.getRoles();
        console.log('Load roles: ', gridRoles);

        // Update UI context
        this.context.currentRoleModel = gridRoles;

        this.context.updateUI();
    } catch (error) {
        console.error('Error loading roles:', error);
        throw error; // Re-throw the error to propagate it further if needed
    }
  }

    /**
   * create Role: called by UI
   */
    public async createRole(roleToCreate: RoleCreateType): Promise<RoleIDType> {
      try {
        console.log("Creating new role", roleToCreate);
        const result = await APIService.createRole(roleToCreate);
        console.log('Created role', result);
        this.context.newRole = [];
  
        await this.loadRoles();
        
        return result;
      } catch (error) {
        console.error("Error saving role", error);
        throw error;
      }
    }

  /**
   * save updated Role: called by UI
   */
  public async saveRole(roleToSave: RoleType): Promise<void> {
    try {
      if (roleToSave.roleID !== null) {
        console.log("Saving role", roleToSave);
      }
      const result = await APIService.saveRole(roleToSave);
      console.log('Saved role', result);
      this.context.newRole = [];

      await this.loadRoles();
    } catch (error) {
      console.error("Error saving role", error);
    }
  }

  /**
   * Delete a Role: called by UI
   */
  public async deleteRole(roleToDelete: RoleType): Promise<void> {
    try {
      console.log("Removing role", roleToDelete);
      if (roleToDelete.roleID === null) {
        throw new Error('Role ID is null')
      }

      const result = await APIService.deleteRole(roleToDelete.roleID);
      console.log('Removed role', result);
      this.context.removeRole = [];

      await this.loadRoles();
    } catch (error) {
      console.error("Error deleting role", error);
    }
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RoleService();
