const api_config = {
  apiTimeouts: {
    getOrgHierarchy: 15000, // 15 seconds
    getRoles: 30000, // 30 seconds
    saveRole: 10000, // 10 seconds
    deleteRole: 10000, // 10 seconds
    getEmployees: 30000, // 30 seconds
    getRoleAssignments: 30000, // 30 seconds
    addRoleAssignment: 10000, // 10 seconds
    deleteRoleAssignment: 10000, // 10 seconds
  },
  apiBaseURL: process.env.REACT_APP_API_BASE_URL,
};

export default api_config;
