import { OrgHierarchyTabularType } from '../models/OrgHierarchyTabular';
import { RoleType, RoleCreateType, RoleIDType } from '../models/Role';
import { EmployeeType } from '../models/Employee';
import { RoleAssignmentType } from '../models/RoleAssignment';

export const convertAPIRoleToUIRole = (apiRole: any): RoleType => {
  return {
    roleID: apiRole.role_id,
    roleName: apiRole.role_name.trim(),
    roleDescription: apiRole.role_description ? apiRole.role_description.trim() : null,
    reachRequired: apiRole.reach_required,
    roleOwner: apiRole.role_owner,
    roleOwnerEmail: apiRole.role_owner_email,
    defaultReachLevel: apiRole.default_reach_level,
    roleDescriptionWithID: `${apiRole.role_id} - ${apiRole.role_name.trim()}`
  };
};

export const convertUIRoleToAPIRole = (uiRole: RoleType): any => {
  return {
    role_id: uiRole.roleID,
    role_name: uiRole.roleName,
    role_description: uiRole.roleDescription,
    reach_required: uiRole.reachRequired,
    role_owner: uiRole.roleOwner,
    role_owner_email: uiRole.roleOwnerEmail,
    default_reach_level: uiRole.defaultReachLevel
  };
};

export const convertCreateUIRoleToAPIRole = (uiRole: RoleCreateType): any => {
  return {
    role_name: uiRole.roleName,
    role_description: uiRole.roleDescription,
    reach_required: uiRole.reachRequired,
    role_owner: uiRole.roleOwner,
    role_owner_email: uiRole.roleOwnerEmail,
    default_reach_level: uiRole.defaultReachLevel
  };
};

export const convertCreateAPIRoleToUIRole = (apiRole: any): RoleIDType => {
  return {
    roleID: apiRole.role_id
  }
}

export const convertAPIOrgHierarchyToUIOrgHierarchy = (apiNode: any): OrgHierarchyTabularType => {
  return {
    nodeID: apiNode.node_id,
    nodeName: apiNode.node_name,
    parentNodeID: apiNode.parent_node_id,
    level: apiNode.level,
    fullyQualifiedNodeDescription: apiNode.fully_qualified_node_description,
    reverseFullyQualifiedNodeDescription: apiNode.reverse_fully_qualified_node_description,
    totalCompanyLongName: apiNode.total_company_long_name,
    divisionLongName: apiNode.division_long_name,
    level2LongName: apiNode.level_2_long_name,
    subsidiaryorLOBLongName: apiNode.subsidiary_or_lob_long_name,
    consolidatedRegionsLongName: apiNode.consolidated_regions_long_name,
    regionLongName: apiNode.region_long_name,
    MktOrBranchOrCorpDeptLongName: apiNode.market_or_branch_or_corp_dept_long_name,
    BUOrCostCenterOrStationLongName: apiNode.bu_or_bou_or_cost_center_or_station_long_name,
    isMarket: apiNode.is_market,
    marketID: apiNode.market_id,
    marketName: apiNode.market_name,
    businessUnitID: apiNode.business_unit_id,
    businessUnitName: apiNode.business_unit_name,
    isSharedServiceBusinessUnit: apiNode.is_shared_service_business_unit,
    isStation: apiNode.is_station,
    stationType: apiNode.station_type,
    fccID: apiNode.fcc_id
  };
};

export const convertAPIRoleAssignmentToUIRoleAssignment = (apiRoleAssignment: any): RoleAssignmentType => {
  const new_node: RoleAssignmentType = {
    roleAssignmentID: apiRoleAssignment.role_assignment_id,
    assignmentType: apiRoleAssignment.assignment_type,
    roleID: apiRoleAssignment.role_id,
    employeeID: apiRoleAssignment.employee_id,
    nodeID: apiRoleAssignment.node_id
  };
  //console.log("converting from API to UI form: ",apiRoleAssignment, " result is ", new_node)
  return new_node
};

export const convertUIRoleAssignmentToAPIRoleAssignment = (uiRoleAssignment: RoleAssignmentType): any => {
  const apiRoleAssignment = {
    assignment_type: uiRoleAssignment.assignmentType,
    role_id: uiRoleAssignment.roleID,
    employee_id: uiRoleAssignment.employeeID,
    node_id: uiRoleAssignment.nodeID,
    assignment_status: 'assigned' // Ensure this is set based on your logic
  };
  // console.log("converting from UI to API form: ", uiRoleAssignment, " result is ", apiRoleAssignment);
  return apiRoleAssignment;
};

export const convertAPIEmployeeToUIEmployee = (apiEmployee: any): EmployeeType => {
  return {
    employeeID: apiEmployee.employee_id,
    costCenter: apiEmployee.cost_center,
    firstName: apiEmployee.first_name,
    lastName: apiEmployee.last_name,
    fullName: apiEmployee.full_name,
    businessTitle: apiEmployee.business_title,
    division: apiEmployee.division,
    workEmailAddress: apiEmployee.work_email_address,
    isActive: apiEmployee.is_active,
    isTerminated: apiEmployee.is_terminated,
    hireDate: apiEmployee.hire_date,
    termDate: apiEmployee.term_date,
    workerType: apiEmployee.worker_type,
    fullNameWithID: `${apiEmployee.employee_id} - ${apiEmployee.full_name}`
  };
};
