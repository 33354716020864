import './App.css';
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { scopes } from './auth.ts';
import NavDrawer from './pages/NavDrawer';
import { RoleAssignmentProvider } from './RoleAssignmentProvider'; 


function App() {
  // this hook will automatically issue a redirect login if the user isn't logged in yet
  useMsalAuthentication(InteractionType.Redirect, { scopes });

  return (
    // Everything below `AuthenticatedTemplate` will only render if the user is logged in
    <AuthenticatedTemplate>
      <RoleAssignmentProvider>
        <div className="App">
          <NavDrawer/>
        </div>
      </RoleAssignmentProvider>
    </AuthenticatedTemplate>
  );
}

export default App;

