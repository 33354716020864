import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_AZURE_APP_REDIRECT_URI,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
  },
};

export const pca = new PublicClientApplication(configuration);

export const scopes = ['User.Read'];

export async function getAccessToken() {
  const accounts = pca.getAllAccounts();

  const request = {
    scopes,
    account: accounts[0],
  };

  const authResult = await pca.acquireTokenSilent(request);

  return {
    token: authResult.accessToken,
  };
}
