import { CompleteRoleAssignmentType } from '../models/CompleteRoleAssignment';
import { RoleAssignmentModelUtilities } from '../utils/RoleAssignmentModelUtilities';
import { RoleAssignmentType } from '../models/RoleAssignment';
import { OrgHierarchyTabular, OrgHierarchyTabularType } from  '../models/OrgHierarchyTabular';
import { OrgHierarchyNode } from '../models/OrgHierarchyNode';
import APIService from '../api/APIService';
// import APIService from '../api/APIServiceTesting';  use this line for static data replacement
import { RoleAssignmentUIContextType, createInitialContext } from  '../models/RoleAssignmentUIContext';

class RoleAssignmentService {
  public  context: RoleAssignmentUIContextType = createInitialContext();
  private orgHierarchy: OrgHierarchyTabularType[] = [];
  private orgHierarchyTree: OrgHierarchyNode = OrgHierarchyNode.bogusNode;
  public isInitialized: boolean = false;

  public async initAsyncData(): Promise<void> {
    try {
        const orgHierarchyData: OrgHierarchyTabularType[] = await APIService.getOrgHierarchy();
        // Merge duplicate nodes before constructing the hierarchy tree
        const mergedOrgHierarchyData: OrgHierarchyTabularType[] = OrgHierarchyTabular.mergeDuplicateNodes(orgHierarchyData);
        this.orgHierarchy = mergedOrgHierarchyData;
        this.orgHierarchyTree = OrgHierarchyNode.fromTabularData(mergedOrgHierarchyData);
    } catch (error) {
        console.error('Error initializing RoleAssignmentService:', error);
    }
}

  private async ensureInitialized(): Promise<void> {
    if (!this.orgHierarchyTree) {
      await this.initAsyncData();
    }
  }

  /* --------------------------------------------Org Hierarcy stuff ------------------------*/
  public getOrgHierarchyTabular(): OrgHierarchyTabularType[] {
    return this.orgHierarchy;
  }

  public getOrgHierarchyTree(): OrgHierarchyNode | null {
    return this.orgHierarchyTree;
  }

  /* --------------------------------------------RoleAssignment stuff ------------------------*/

  /**
   * loadCompleteAssignments: called by UI
   * 0. Handles unsaved changes based on dropUnsavedChangesOverride
   * 1. Runs getRoleAssignments to query the API
   * 2. Calls calcCompleteAssignmentsFromRoleAssignments with the new role assignments
   * 3. Updates UI context and triggers UI refresh via driveUIupdate
   */
  public async loadCompleteAssignments(employeeID: string, roleID: string, dropUnsavedChangesOverride: boolean | null = false): Promise<void> {
    // Validate parameters
    // console.log("Inside async load completeAssignments, requested to load ", { employeeID, roleID });
    if (!employeeID || !roleID) {
        throw new Error('Invalid role assignment query: both employeeID and roleID must be provided and not blank');
    }

    // Check for unsaved changes
    if (dropUnsavedChangesOverride !== true && (this.context.newRoleAssignments.length > 0 || this.context.removeRoleAssignments.length > 0)) {
        throw new Error('Unsaved changes will be lost');
    }

    // Handle dropUnsavedChangesOverride
    if (dropUnsavedChangesOverride === true) {
        this.context.newRoleAssignments = [];
        this.context.removeRoleAssignments = [];
    }

    try {
        const roleAssignments = await this.getRoleAssignments(employeeID, roleID);
        // console.log(" load complete Assignments roles are ",roleAssignments)
        const { completeAssignments, removedAssignments } = this.calcCompleteAssignmentsFromRoleAssignments(roleAssignments);
        //console.log(" after conversion (calcCompleteAssignmentsFromRoleAssignments)", completeAssignments)
        // Quietly remove the extra assignments
        this.quietlyRemoveRoleAssignments(removedAssignments);

        // Update UI context
        this.context.currentUIQueryConditions = { employeeID, roleID };
        this.context.currentRoleAssignments = roleAssignments;
        this.context.currentCompleteRoleAssignmentModel = completeAssignments;

        this.context.updateUI();
    } catch (error) {
        console.error('Error loading complete assignments:', error);
        throw error; // Re-throw the error to propagate it further if needed
    }
  }

  

  /**
   * calcCompleteAssignmentsFromRoleAssignments: calculates complete role assignments from role assignments
   * 1. Uses RoleAssignmentModelUtilities to construct a new complete role assignments array
   * 2. Returns the result
   */
  private calcCompleteAssignmentsFromRoleAssignments(roleAssignments: RoleAssignmentType[]): { completeAssignments: CompleteRoleAssignmentType[], removedAssignments: RoleAssignmentType[] } {
    return RoleAssignmentModelUtilities.createCompleteAssignmentFromRoleAssignmentsAndOrgHierarchy(this.orgHierarchy, roleAssignments, this.orgHierarchyTree, this.context);
  }

  private quietlyRemoveRoleAssignments(removedAssignments: RoleAssignmentType[]): void {
    removedAssignments.forEach(roleAssignment => {
        this.context.removeRoleAssignments.push(roleAssignment);
        this.context.currentRoleAssignments = this.context.currentRoleAssignments.filter(
            ra => !(ra.nodeID === roleAssignment.nodeID && ra.employeeID === roleAssignment.employeeID && ra.roleID === roleAssignment.roleID)
        );
    });
}

  /**
   * getRoleAssignments: private method to get role assignments
   * 1a. Throws an error if the query is invalid
   * 1b. Queries the API for role assignments
   * 2. Returns the role assignments array
   */
  private async getRoleAssignments(employeeID: string, roleID: string): Promise<RoleAssignmentType[]> {
    // Ensure both parameters are non-null and non-empty
    if (!employeeID.trim() && !roleID.trim()) {
      throw new Error('Invalid role assignment query: At least one of employeeID or roleID must be provided');
    }
  
    // Assuming APIService.getRoleAssignments expects non-empty strings
    return await APIService.getRoleAssignments(employeeID, roleID);
  }
  

  /**
   * addRoleAssignment: adds a role assignment to the UI context
   * 1. Constructs a new role assignment
   * 1a. Updates cached changes (newRoleAssignments and removeRoleAssignments)
   * 2. Updates current state
   * 2a. Adds the role assignment to the current role assignments in the UI context
   * 2b. Calls calcCompleteAssignmentsFromRoleAssignments
   * 2c. Updates the UI context with the new complete role assignments
   * 5. Triggers UI refresh via driveUIupdate
   */
  public addRoleAssignment(nodeID: number, employeeID: string, roleID: string): void {
    const roleAssignmentToAdd: RoleAssignmentType = {
        nodeID,
        employeeID,
        roleID,
        roleAssignmentID: null,
        assignmentType: 2
    };

  

    // Update cached changes
    console.log("adding role assigment from UI action", roleAssignmentToAdd)
    this.context.newRoleAssignments.push(roleAssignmentToAdd);
    this.context.removeRoleAssignments = this.context.removeRoleAssignments.filter(
        ra => !(ra.nodeID === nodeID && ra.employeeID === employeeID && ra.roleID === roleID)
    );

    // Update current state
    this.context.currentRoleAssignments.push(roleAssignmentToAdd);
    const { completeAssignments, removedAssignments } = this.calcCompleteAssignmentsFromRoleAssignments(this.context.currentRoleAssignments);

    // Quietly remove the extra assignments
    this.quietlyRemoveRoleAssignments(removedAssignments);

    this.context.currentCompleteRoleAssignmentModel = completeAssignments;
    this.context.updateUI();
  }
public checkIfUnsavedChanges(): boolean {
  // console.log("checking for unsaved changes in model manager remove length:",this.context.removeRoleAssignments.length,
    // " add length = ",this.context.newRoleAssignments.length)
  const hasRemovedRoles = !this.context.removeRoleAssignments || this.context.removeRoleAssignments.length > 0;
  const hasNewRoles = !this.context.newRoleAssignments || this.context.newRoleAssignments.length > 0;
  // console.log("checking for unsaved changes in model manager",hasRemovedRoles || hasNewRoles )
  return hasRemovedRoles || hasNewRoles;
}

public updateContextWithEmployeeID(employeeID: string) {
    this.context.currentEmployeeID = employeeID;
    this.context.updateUI();
}

public updateContextWithRoleID(roleID: string) {
    this.context.currentRoleID = roleID;
    this.context.updateUI();
}

  /**
   * removeRoleAssignment: removes a role assignment from the UI context
   * 1. Updates cached changes (removeRoleAssignments and newRoleAssignments)
   * 2. Updates current state
   * 2a. Removes the role assignment from the current role assignments in the UI context
   * 2b. Calls calcCompleteAssignmentsFromRoleAssignments
   * 2c. Updates the UI context with the new complete role assignments
   * 5. Triggers UI refresh via driveUIupdate
   */
  public removeRoleAssignment(roleAssignmentToDelete: RoleAssignmentType): void {
    // Update cached changes
    console.log("removing role assigment", roleAssignmentToDelete)
    this.context.removeRoleAssignments.push(roleAssignmentToDelete);
    this.context.newRoleAssignments = this.context.newRoleAssignments.filter(
        ra => !(ra.nodeID === roleAssignmentToDelete.nodeID && ra.employeeID === roleAssignmentToDelete.employeeID && ra.roleID === roleAssignmentToDelete.roleID)
    );

    // Update current state
    this.context.currentRoleAssignments = this.context.currentRoleAssignments.filter(
        ra => !(ra.nodeID === roleAssignmentToDelete.nodeID && ra.employeeID === roleAssignmentToDelete.employeeID && ra.roleID === roleAssignmentToDelete.roleID)
    );
    const { completeAssignments, removedAssignments } = this.calcCompleteAssignmentsFromRoleAssignments(this.context.currentRoleAssignments);

    // Quietly remove the extra assignments
    this.quietlyRemoveRoleAssignments(removedAssignments);

    this.context.currentCompleteRoleAssignmentModel = completeAssignments;
    this.context.updateUI();
  }


  /**
   * saveRoleAssignmentChanges: processes unsaved changes and saves them to the API
   * 1. Loops through newRoleAssignments[] and performs POST requests
   * 2. Loops through removeRoleAssignments[] and performs DELETE requests
   * 3. Refreshes the current state by calling loadCompleteAssignments
   */
  public async saveRoleAssignmentChanges(): Promise<void> {
    try {
      // Process new role assignments
      for (const newRoleAssignment of this.context.newRoleAssignments) {
        console.log("new assignment is good? ",newRoleAssignment)
        const result = await APIService.addRoleAssignment(newRoleAssignment);
        console.log('Added role assignment:', result);
        newRoleAssignment.roleAssignmentID = result.roleAssignmentID; // Update with the returned ID
      }
      this.context.newRoleAssignments = [];

      // Process removed role assignments
      for (const removeRoleAssignment of this.context.removeRoleAssignments) {
        if (removeRoleAssignment.roleAssignmentID === null) {
          throw new Error('Role assignment ID is null');
        }

        const result = await APIService.deleteRoleAssignment(removeRoleAssignment.roleAssignmentID);
        console.log('Removed role assignment:', result);
      }
      this.context.removeRoleAssignments = [];

      // Refresh current state
      await this.loadCompleteAssignments(this.context.currentUIQueryConditions.employeeID, this.context.currentUIQueryConditions.roleID);
    } catch (error) {
      console.error('Error saving role assignment changes:', error);
    }
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RoleAssignmentService();