import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import api_config from './config';
import { OrgHierarchyTabularType } from '../models/OrgHierarchyTabular';
import { RoleType, RoleCreateType, RoleIDType } from '../models/Role';
import { EmployeeType } from '../models/Employee';
import { RoleAssignmentType } from '../models/RoleAssignment';
import {
  convertAPIRoleToUIRole,
  convertUIRoleToAPIRole,
  convertCreateUIRoleToAPIRole,
  convertCreateAPIRoleToUIRole,
  convertAPIEmployeeToUIEmployee,
  convertAPIOrgHierarchyToUIOrgHierarchy,
  convertAPIRoleAssignmentToUIRoleAssignment,
  convertUIRoleAssignmentToAPIRoleAssignment
} from '../utils/dataModelConversionUtilities';
import { getAccessToken } from '../auth';

class APIService {
  private baseURL = api_config.apiBaseURL;

  async getDefaultHeaders(): Promise<RawAxiosRequestHeaders> {
    return {
      Authorization: `Bearer ${(await getAccessToken()).token}`
    }
  }

  async getOrgHierarchy(): Promise<OrgHierarchyTabularType[]> {
    try {
      const response = await axios.get<any[]>(`${this.baseURL}/organizational-node-fully-enriched`, {
        timeout: api_config.apiTimeouts.getOrgHierarchy,
        headers: await this.getDefaultHeaders(),
      });
      return response.data.map(convertAPIOrgHierarchyToUIOrgHierarchy);
    } catch (error) {
      console.error('Error fetching organizational hierarchy:', error);
      throw error;
    }
  }

  async getRoles(): Promise<RoleType[]> {
    try {
      const response = await axios.get<any[]>(`${this.baseURL}/roles`, {
        timeout: api_config.apiTimeouts.getRoles,
        headers: await this.getDefaultHeaders(),
      });
      return response.data.map(convertAPIRoleToUIRole);
    } catch (error) {
      console.error('Error fetching roles:', error);
      throw error;
    }
  }

  async saveRole(role: RoleType): Promise<RoleType> {
    try {
      const { roleDescriptionWithID, ...updatedRole } = role //removed roleDescriptionWithID field
      const apiRole = convertUIRoleToAPIRole(updatedRole);
      const response = await axios.put<any>(`${this.baseURL}/role/${role.roleID}`, apiRole, {
        timeout: api_config.apiTimeouts.saveRole,
        headers: await this.getDefaultHeaders(),
      });
      return convertAPIRoleToUIRole(response.data);
    } catch (error) {
      console.error('Error saving role:', error);
      throw error;
    }
  }

  
  async createRole(role: RoleCreateType): Promise<RoleIDType> {
    try {
      const apiRole = convertCreateUIRoleToAPIRole(role);
      const response = await axios.post<any>(`${this.baseURL}/role`, apiRole, {
        timeout: api_config.apiTimeouts.saveRole,
        headers: await this.getDefaultHeaders(),
      });
      console.log("Response", response.data);
      return convertCreateAPIRoleToUIRole(response.data);
    } catch (error) {
      console.error('Error creating role:', error);
      throw error;
    }
  }

  async deleteRole(roleId: number): Promise<void> {
    try {
      await axios.delete(`${this.baseURL}/role/${roleId}`, {
        timeout: api_config.apiTimeouts.deleteRole,
        headers: await this.getDefaultHeaders(),
      });
    } catch (error) {
      console.error(`Error deleting role with ID: ${roleId}`, error);
      throw error;
    }
  }

  async getEmployees(): Promise<EmployeeType[]> {
    try {
      const response = await axios.get<any[]>(`${this.baseURL}/employees`, {
        timeout: api_config.apiTimeouts.getEmployees,
        headers: await this.getDefaultHeaders(),
      });
      return response.data.map(convertAPIEmployeeToUIEmployee);
    } catch (error) {
      console.error('Error fetching employees:', error);
      throw error;
    }
  }

  
  async getRoleAssignments(employeeId: string, roleId: string, includePermitted = false): Promise<RoleAssignmentType[]> {
      if (!employeeId.trim() || !roleId.trim()) {
          throw new Error('Invalid role assignment query: both employeeId and roleId must be provided and not blank');
      }
  
      const url = `${this.baseURL}/role-assignments`;
      const params = {
          employee_id: employeeId,
          role_id: roleId,
          include_permitted: includePermitted
      };
  
      const axiosConfig: AxiosRequestConfig = {
          url: url,
          method: 'get',
          params: params,
          timeout: api_config.apiTimeouts.getRoleAssignments,
          headers: await this.getDefaultHeaders(),
      };
  
      // Manually construct and log the full URL
      const fullUrl = `${url}?${new URLSearchParams(params as any).toString()}`;
      console.log("Getting role assignments URL: ", fullUrl);
  
      try {
          const response = await axios.request<any[]>(axiosConfig);
          const filteredList = response.data.filter(apiRoleAssignment => apiRoleAssignment.assignment_status === 'assigned');
          const convertedList = filteredList.map(convertAPIRoleAssignmentToUIRoleAssignment);
          // console.log("Finally the converted list: ", convertedList);
          return convertedList;
  
      } catch (error) {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
              console.warn('Role assignments not found (404). Returning empty array.');
              return [];
          } else {
              console.error('Error fetching role assignments:', error);
              throw error;
          }
      }
  }
  
  

  async addRoleAssignment(roleAssignment: RoleAssignmentType): Promise<RoleAssignmentType> {
    const apiRoleAssignment = convertUIRoleAssignmentToAPIRoleAssignment(roleAssignment);
    // console.log("saving this new role assignment ", roleAssignment);
    // console.log("JSON payload: ", JSON.stringify(apiRoleAssignment, null, 2)); // Pretty-print the JSON for easier reading

    try {
      const response = await axios.post<any>(`${this.baseURL}/role-assignment`, apiRoleAssignment, {
        timeout: api_config.apiTimeouts.addRoleAssignment,
        headers: await this.getDefaultHeaders(),
      });
      return convertAPIRoleAssignmentToUIRoleAssignment(response.data);
    } catch (error) {
      console.error('Error adding role assignment:', error);
      throw error;
    }
  }
  async deleteRoleAssignment(roleAssignmentId: number): Promise<void> {
    try {
      await axios.delete(`${this.baseURL}/role-assignment/${roleAssignmentId}`, {
        timeout: api_config.apiTimeouts.deleteRoleAssignment,
        headers: await this.getDefaultHeaders(),
      });
    } catch (error) {
      console.error(`Error deleting role assignment with ID: ${roleAssignmentId}`, error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new APIService();
