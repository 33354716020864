import { RoleAssignmentType } from "./RoleAssignment";
export interface CompleteRoleAssignmentType {
    id: string;
    assignmentType: number;
    roleID: string;
    employeeID: string;
    roleAssignmentID: number | null;
    nodeID: number ;
    nodeName: string | null;
    parentNodeID: number | null;
    level: number;
    fullyQualifiedNodeDescription: string | null;
    reverseFullyQualifiedNodeDescription: string | null;
    totalCompanyLongName: string | null;
    divisionLongName: string | null;
    level2LongName: string | null;
    subsidiaryorLOBLongName: string | null;
    consolidatedRegionsLongName: string | null;
    regionLongName: string | null;
    MktOrBranchOrCorpDeptLongName: string | null;
    BUOrCostCenterOrStationLongName: string | null;
    assignmentStatus: string | null;
    isMarket: number | null;
    marketID: string | null;
    marketName: string | null;
    businessUnitID: string | null;
    businessUnitName: string | null;
    isSharedServiceBusinessUnit: number | null;
    isStation: number | null;
    stationType: string | null;
    isAssigned: number;
    isPermitted: number;
    descendantAssignedCount: number;
    descendantPermittedCount: number;
}

export class CompleteRoleAssignment {
    // existing properties...
  
    static toRoleAssignment(completeRoleAssignment: CompleteRoleAssignmentType): RoleAssignmentType {
      console.log("converting complete role assignment type to role assignment type input is ",completeRoleAssignment)
      return {
        roleAssignmentID: completeRoleAssignment.roleAssignmentID,
        nodeID: completeRoleAssignment.nodeID,
        employeeID: completeRoleAssignment.employeeID,
        roleID: completeRoleAssignment.roleID,
        assignmentType: completeRoleAssignment.assignmentType
      };
    }
  }

  export const getRowHoverText = (assignment: CompleteRoleAssignmentType): JSX.Element => {
    return (
      <div>
        {assignment.assignmentStatus === 'assigned' && (
          <>
            <div>Role Assignment ID: {assignment.roleAssignmentID}</div>
            <div>Assignment Type: {assignment.assignmentType}</div>
          </>
        )}
        {assignment.isMarket === 1 && (
          <>
            <div>Market ID: {assignment.marketID}</div>
            <div>Market Name: {assignment.marketName}</div>
          </>
        )}
        {assignment.level === 7 && (
          <>
            <div>Business Unit ID: {assignment.businessUnitID}</div>
            <div>Business Unit Name: {assignment.businessUnitName}</div>
          </>
        )}
        {assignment.isStation === 1 && (
          <>
            <div>Station Type: {assignment.stationType}</div>
            {/* <div>FCC ID: {assignment.fccID}</div> */}
          </>
        )}
      </div>
    );
  };
  